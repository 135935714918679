import {
  FeatureFlags,
  FeatureFlagsConfig,
  FeatureFlagsResponse,
} from './types';
import axios, { AxiosResponse } from 'axios';

export const fetchFeatureFlags = (
  config: FeatureFlagsConfig,
  signal: AbortSignal,
): Promise<FeatureFlags> =>
  axios
    .get<FeatureFlagsResponse>(config.url, { signal })
    .then((response: AxiosResponse<FeatureFlagsResponse>) => {
      return {
        loaded: true,
        enabledFlags: response.data.featureToggles ?? [],
      };
    });

export default {
  fetchFeatureFlags,
};
