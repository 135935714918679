import { FeatureFlags, FeatureFlagsContext } from './types';
import { useContext } from 'react';

export function useFeatureFlags(): FeatureFlags {
  return useContext<FeatureFlags>(FeatureFlagsContext);
}

export function useFeatureFlag(name: string): boolean {
  const { loaded = false, enabledFlags = [] } = useFeatureFlags();
  return loaded && enabledFlags.includes(name);
}
