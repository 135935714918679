import React, { JSX, ReactNode } from 'react';
import { useFeatureFlag } from './hooks';

type RenderFunction = (isFlagEnabled: boolean) => JSX.Element;

export function Feature({
  name,
  failoverContent,
  children,
}: {
  name: string;
  failoverContent?: ReactNode;
  children?: ReactNode | RenderFunction;
}): JSX.Element | null {
  const isFlagEnabled = useFeatureFlag(name);
  if (typeof children === 'function') {
    return children(isFlagEnabled);
  }
  if (isFlagEnabled) {
    return children ? <>{children}</> : null;
  }
  return failoverContent ? <>{failoverContent}</> : null;
}
