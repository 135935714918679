import { FeatureFlags, FeatureFlagsConfig, FeatureFlagsContext } from './types';
import * as React from 'react';
import api from './api';

export function FeatureFlagsProvider({
  config,
  children,
}: {
  config: FeatureFlagsConfig;
  children: React.ReactNode;
}) {
  const [flags, setFlags] = React.useState<FeatureFlags>({
    loaded: false,
    enabledFlags: [],
  });
  config = {
    enabled: true,
    ...config,
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (config.enabled && !flags.loaded) {
      api
        .fetchFeatureFlags(config, signal)
        .then((featureFlags: FeatureFlags) => setFlags(featureFlags))
        .catch((error: any) => {
          if (config.onError) {
            config.onError(error);
          }
        });
    }
    return () => controller.abort();
  }, [config, flags.loaded]);

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
