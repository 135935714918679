import { createContext } from 'react';

export type FeatureFlagsConfig = {
  url: string;
  enabled?: boolean;
  onError?: (reason: any) => void;
};

export type FeatureFlags = {
  loaded: boolean;
  enabledFlags: string[];
};

export type FeatureFlagsResponse = {
  featureToggles: string[];
  _link: {
    self: {
      href: string;
    };
  };
};

export const FeatureFlagsContext = createContext<FeatureFlags>({
  loaded: false,
  enabledFlags: [],
});
